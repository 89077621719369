import Separator from '@/components/Common/Separator';
import NotFound from '@/components/NotFound/NotFound';

const ErrorPage = () => {
   return (
      <>
         <NotFound />

         <Separator />
      </>
   );
};

export default ErrorPage;
